import AnimatedIcon from '@/components/molecules/AnimatedIcon/AnimatedIcon';
import { INotificationRow } from '@/components/molecules/NotificationRow/NotificationRow';
import NotificationDrawer from '@/components/organisms/NotificationDrawer/NotificationDrawer';
import { sdk } from '@/lib/dataSource/lostApi/common';
import { useUserContext } from '@/lib/hooks/userContext/UserContext';
import { useWebSocketService } from '@/lib/hooks/websocket/useWebSocket';
import { useComponentFocused } from '@/lib/utils/ComponentFocused/ComponentFocused';
import { NotificationAlertState } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/services/websocket/uiNotifications/interfaces';
import { useCallback, useEffect, useState } from 'react';

/**
 * Helper function to init the websocket hook and retrieve messages
 *
 * @returns {object} - The array of notifications and the websocket service
 *   instance
 */
const useUiNotifications = () => {
  const { idToken } = useUserContext();
  const { service, status, messages } = useWebSocketService(
    () => sdk.uiNotifications({ authToken: idToken }),
    {
      disable: !idToken,
      messageBufferSize: 1,
    }
  );

  useEffect(() => {
    if (service && status === 'open') {
      service?.getAlerts();
    }
  }, [service, status]);

  /**
   * Updates the message to read status on click
   *
   * @param {string} alertId - The id of the alert
   * @param {NotificationAlertState} state - The state of the alert
   * @returns {void}
   */
  const handleNotificationClick = (
    alertId: string,
    state: NotificationAlertState
  ) => {
    if (
      service &&
      status === 'open' &&
      (state === 'unread' || state === 'unseen')
    ) {
      service?.readAlert(alertId);
    }
  };

  /**
   * This will map the values of the alert to an array of INotificationRow
   * interface then filter out any notifications without images, link urls, or
   * dates
   *
   * @constant {INotificationRow[]} notifications - The array of notifications
   */
  const notifications: INotificationRow[] = (messages.last ?? [])
    .map((alert) => ({
      alertId: alert.alertId,
      image: alert.ctaDetails.photoUrl,
      headline: alert.ctaDetails.heading,
      linkLabel: alert.ctaDetails.linkText,
      linkUrl: alert.ctaDetails.url,
      date: alert.ctaDetails?.createdAt
        ? new Date(alert.ctaDetails.createdAt)
        : undefined,
      state: alert.ctaDetails.state,
      onClick: handleNotificationClick,
      eventType: alert.eventType,
      petId: alert.petEntityId,
    }))
    .filter(
      (notification) =>
        notification.image &&
        notification.linkUrl &&
        notification.date &&
        notification.state !== 'dismissed'
    );

  return {
    notifications,
    service,
  };
};

/**
 * Notifications with drawer for the navbar
 *
 * @returns {React.FC} Notifications for the Navbar
 */
const Notifications = () => {
  const { notifications: listNotifications, service } = useUiNotifications();
  const { ref, isComponentFocused } = useComponentFocused(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showAnimation, setShowAnimation] = useState(
    listNotifications.some((notification) => notification.state === 'unseen')
  );

  // Optimistic update
  const [isUpdating, setIsUpdating] = useState(false);

  // Updates all the unseen messages when the user opens the drawer
  const handleSeeAlert = useCallback(() => {
    // Optimistic update to hide the animation
    setShowAnimation(false);
    setIsUpdating(true);
    const unseenNotifications = listNotifications.filter(
      (notification) => notification.state === 'unseen'
    );
    if (unseenNotifications.length > 0) {
      unseenNotifications.forEach((notification) => {
        service?.seeAlert(notification.alertId);
      });
    }
  }, [listNotifications, service]);

  // On click outside of the component
  useEffect(() => {
    if (!isComponentFocused) {
      setIsDrawerOpen(false);
    }
  }, [isComponentFocused]);

  // If we receive new unseen notifications
  useEffect(() => {
    if (!isUpdating) {
      setShowAnimation(
        listNotifications.some(
          (notification) => notification.state === 'unseen'
        )
      );
    }
  }, [listNotifications, isUpdating]);

  return (
    <div className="relative z-[12]" ref={ref}>
      <AnimatedIcon
        iconType="bell"
        showAnimation={!isUpdating && showAnimation}
        onClick={() => {
          const _isDrawerOpen = !isDrawerOpen;
          setIsDrawerOpen(_isDrawerOpen);
          handleSeeAlert();
          if (!_isDrawerOpen) {
            // Closing the drawer clears the optimistic update
            setIsUpdating(false);
          }
        }}
        animationClassName="mr-0.5 mt-1"
      />
      <NotificationDrawer
        notifications={listNotifications}
        closeDrawer={() => setIsDrawerOpen(false)}
        className={`right-0 fixed sm:absolute sm:top-11 ${
          isDrawerOpen ? 'flex' : 'hidden'
        }`}
        onButtonClick={() => setIsDrawerOpen(false)}
      />
    </div>
  );
};

export default Notifications;
